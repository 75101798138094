import { useAxios } from '@/hooks/useAxios'

const request = useAxios()

//************************************************************************
// 注册
export const Register = data => {
  return request.post({
    url: '/common/register',
    data: data,
  })
}

//登录
export const Login = data => {
  return request.post({
    url: '/common/login',
    data: data,
  })
}

// 鉴权
export const Auth = data => {
  return request.post({
    url: '/set_session',
    data: data,
  })
}

// 上传图片
export const uploadFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return request.post({
    url: '/upload_file',
    headersType: 'multipart/form-data',
    data: formData,
  })
}

// 鉴权
export const imitate_login = data => {
  return request.post({
    url: '/login',
    data: data,
  })
}

// 单点登录
export const jump_login = data => {
  return request.post({
    url: '/jump_login',
    data: data,
  })
}
